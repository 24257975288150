<template>
	<div id="Chart">
		盘丝大仙
	</div>
</template>

<script>
	export default {
		name: 'Chart',
		components: {},
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>

<style lang="less" scoped>
	#Chart {}
</style>